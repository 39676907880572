
export const decorateEnv = (env) => {
    env.build.buildDate = '20231031111622';
    env.build.buildDateEpoch = 1698750982407;
    env.build.buildRevision = 'e92b3547716134702c0288ed08aa07fb65a65131';
    env.build.project = 'abbottamd';
    env.build.part = 'dms';
    env.build.deployment = 'prd';
    env.build.viewport = 'pc';
    env.apiRoot = 'https://amdsfe-api.abbott.com.cn';
    env.production = 'true';
    env.variables.mapApiKey = 'ee95e52bf08006f63fd29bcfbcf21df0';
};

<div class="login-container" style="">
  <div class="title">
<!--    <span class="word-title">LOGO</span>-->
<!--        <img src="assets/imgs/LogoAmd.png"  style="width: 200px;height: 100px;" />-->
  <img src="assets/imgs/logoleft.png"  style="width: 200px;height: 100px;" />
  </div>
  <form nz-form [formGroup]="validateForm" class="login-form" >
    <div>
      <nz-form-item>
        <nz-form-control nzErrorTip="请输入用户名!">
          <!--        nzPrefixIcon="user"-->
          <nz-input-group>
            <img src="assets/imgs/login_account.png" alt="" width="15"
              style="position: absolute;left: 10px;top:10px;z-index: 9" />
            <input class="input-class" type="text" nz-input [(ngModel)]="userName" style="padding-left: 35px"
              formControlName="userName" placeholder="用户名" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="请输入密码!">
          <nz-input-group>
            <img src="assets/imgs/login_psw.png" alt="" width="15"
              style="position: absolute;left: 10px;top:10px;z-index: 9" />
            <input class="input-class" type="password" nz-input [(ngModel)]="password" style="padding-left: 35px"
              formControlName="password" placeholder="密码" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <!--      <nz-form-control nzErrorTip="请输入验证码!">-->
        <!--        <nz-input-group>-->
        <!--          <div style="width: 280px">-->
        <!--            <input class="input-class" type="text" nz-input [(ngModel)]="category"-->
        <!--                   formControlName="category" placeholder="验证码"/>-->
        <!--            <button nz-button (click)="getCaptcha($event)">Get captcha</button>-->
        <!--          </div>-->
        <!--        </nz-input-group>-->
        <!--      </nz-form-control>-->
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="请输入验证码">
          <div nz-row [nzGutter]="8">
            <div nz-col [nzSpan]="16">
              <input nz-input formControlName="captcha" [(ngModel)]="category" placeholder="验证码" />
            </div>
            <div nz-col [nzSpan]="8">
              <button style="margin-left: 15%;color: white" [nzType]="'primary'" nz-button
                (click)="getCaptcha($event)">获取验证码</button>
            </div>
          </div>
        </nz-form-control>
      </nz-form-item>
      <!--    <nz-form-item>-->
      <!--      <nz-form-label-->
      <!--        [nzSm]="6"-->
      <!--        [nzXs]="24"-->
      <!--        nzFor="captcha"-->
      <!--        nzRequired-->
      <!--        nzTooltipTitle="请输入验证码"-->
      <!--      >-->
      <!--        验证码-->
      <!--      </nz-form-label>-->
      <!--      <nz-form-control-->
      <!--        [nzSm]="14"-->
      <!--        [nzXs]="24"-->
      <!--        nzErrorTip="Please input the captcha you got!"-->
      <!--        nzExtra="We must make sure that your are a human."-->
      <!--      >-->
      <!--        <div nz-row [nzGutter]="8">-->
      <!--          <div nz-col [nzSpan]="12">-->
      <!--            <input nz-input formControlName="captcha" id="captcha" />-->
      <!--          </div>-->
      <!--          <div nz-col [nzSpan]="12">-->
      <!--            <button nz-button (click)="getCaptcha($event)">获取验证码</button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </nz-form-control>-->
      <!--    </nz-form-item>-->
      <nz-form-item>
        <nz-form-control>
          <!--        <label nz-checkbox>-->
          <!--          <span>Remember me</span>-->
          <!--        </label>-->
          <!--        <a class="login-form-forgot" class="login-form-forgot">Forgot password</a>-->
          <!--        [nzLoading]="isLoading"-->
          <button nz-button class="login-form-button" [nzType]="'primary'" [nzShape]="'round'" (click)="submitForm()">
            登录
          </button>
          <!--        <nz-spin nzTip="加载中..." [nzSize]="'large'" [nzSimple]="true" *ngIf="isLoading"></nz-spin>-->
          <!--        <a>register now!</a>-->
        </nz-form-control>
      </nz-form-item>

    </div>
  </form>
  <div class="copyright-word">
    @上海太美星环数字科技有限公司 版权所有沪ICP备 11017254号-8
  </div>
</div>
